






























































































































































































































































































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import VerticalBarChart from '../../../components/VerticalBarChart.vue'
import HorizontalBarChart from '../../../components/HorizontalBarChart.vue'
import { appendSitePrefix } from '@/utils/routeUtils'
import moment from 'moment'
import { vxm } from '@/store'
import b64toBlob from 'b64-to-blob'

@Component({
  components: {
    VerticalBarChart,
    HorizontalBarChart,
  },
})
export default class Sales extends Vue {
  appendSitePrefix = appendSitePrefix
  backendLoadedData = false

  reportDefinition: {
    id: number
    name: string
    isDaily: boolean
    isMonthly: boolean
    isProductTypeFilterVisible: boolean
    isCustomerGroupFilterVisible: boolean
    isPriceListFilterVisible: boolean
    isWebshopFilterVisible: boolean
    isEnvironmentalFeeFilterVisible: boolean
    isSupplierFilterVisible: boolean
    hasDetailFields: boolean
    details: {
      title: string
      chartType: string
      chartLabelField: string
      chartSortBy: string
      chartSortByType: string
      chartSortByFormat: string
      chartGroupData: boolean
      chartNumberOfGroups: number
      sortBy: any[]
      sortDesc: any[]
      sortByType: string
      headers: any[]
      tableHeaders: any[]
      chartHeaders: any[]
    }
    standardDetails: {
      title: string
      chartType: string
      chartLabelField: string
      chartSortBy: string
      chartSortByType: string
      chartSortByFormat: string
      chartGroupData: boolean
      chartNumberOfGroups: number
      sortBy: any[]
      sortDesc: any[]
      sortByType: string
      headers: any[]
      tableHeaders: any[]
      chartHeaders: any[]
    }
    compareDetails: {
      title: string
      chartType: string
      chartLabelField: string
      chartSortBy: string
      chartSortByType: string
      chartSortByFormat: string
      chartGroupData: boolean
      chartNumberOfGroups: number
      sortBy: any[]
      sortDesc: any[]
      sortByType: string
      headers: any[]
      tableHeaders: any[]
      chartHeaders: any[]
    }
  }

  defaultChartNumberOfGroups = 10

  reportDateMin = '2006-01-01'
  reportDateMax = moment().format('YYYY-MM-DD')
  predefinedMonths = [1, 3, 6, 12]
  tableSearch = ''
  isReportDetailsVisible = false
  showReportDetails = false

  loadingData = false
  exportToExcelLoading = false
  hasData = false
  title = ''
  reportId = null
  reportGroupId: number
  reportItems: any[]
  reportGroupItems: any[]
  reportDateFrom: string
  reportPreviousDateFrom = ''
  reportDateFromMenu = false
  reportDateTo: string
  reportPreviousDateTo = ''
  reportDateToMenu = false
  reportSupplier: number[]
  reportCustomerGroup: number[]
  reportCustomerSubGroup: number[]
  reportPriceList: number[]
  reportWebshops: number[]
  reportProductType: number
  reportEnvironmentalFeeIncluded: boolean
  isCompare: boolean

  headers = []

  siteCurrencySymbol: string
  suppliers: any[]
  customerGroups: any[]
  customerSubGroups: any[]
  priceLists: any[]
  webshops: any[]
  productTypes: any[]

  tableData = []
  tableTotals = []
  chartData = []

  pagination = {
    sortBy: [],
    sortDesc: [],
    page: 1,
    itemsPerPage: -1,
  }

  chart: any = {
    chartData: {
      labels: [],
      datasets: [] as any[],
    },
    options: [],
  }

  beforeCreate() {
    const queryString = this.$route.query

    if (queryString.dateFrom) {
      this.reportDateFrom = queryString.dateFrom as string
    } else {
      this.reportDateFrom = moment().subtract(3, 'month').format('YYYY-MM')
    }

    if (queryString.dateTo) {
      this.reportDateTo = queryString.dateTo as string
    } else {
      this.reportDateTo = moment().format('YYYY-MM')
    }

    if (queryString.reportSupplier) {
      this.reportSupplier = (queryString.reportSupplier as string).split(',').map((numStr) => parseInt(numStr))
    } else {
      this.reportSupplier = null
    }

    if (queryString.reportCustomerGroup) {
      this.reportCustomerGroup = (queryString.reportCustomerGroup as string)
        .split(',')
        .map((numStr) => parseInt(numStr))
    } else {
      this.reportCustomerGroup = null
    }

    if (queryString.reportCustomerSubGroup) {
      this.reportCustomerSubGroup = (queryString.reportCustomerSubGroup as string)
        .split(',')
        .map((numStr) => parseInt(numStr))
    } else {
      this.reportCustomerSubGroup = null
    }

    if (queryString.reportPriceList) {
      this.reportPriceList = (queryString.reportPriceList as string).split(',').map((numStr) => parseInt(numStr))
    } else {
      this.reportPriceList = null
    }

    if (queryString.reportWebshops) {
      this.reportWebshops = (queryString.reportWebshops as string).split(',').map((numStr) => parseInt(numStr))
    } else {
      this.reportWebshops = null
    }

    if (queryString.reportProductType) {
      this.reportProductType = parseInt(queryString.reportProductType as string)
    } else {
      this.reportProductType = null
    }

    if (queryString.reportEnvironmentalFeeIncluded) {
      this.reportEnvironmentalFeeIncluded = (queryString.reportEnvironmentalFeeIncluded as string) === '1'
    } else {
      this.reportEnvironmentalFeeIncluded = false
    }

    if (queryString.isCompare) {
      this.isCompare = (queryString.isCompare as string) === '1'
    } else {
      this.isCompare = false
    }

    this.$axios.get('/v3/statistics/general/sales/get_initial_data').then((response) => {
      this.reportGroupItems = response.data.data.reportGroups
      this.reportItems = response.data.data.reports
      this.suppliers = response.data.data.suppliers
      this.customerGroups = response.data.data.customerGroups
      this.customerSubGroups = response.data.data.customerSubGroups
      this.priceLists = response.data.data.priceLists
      this.webshops = response.data.data.webshops
      this.productTypes = response.data.data.productTypes
      this.siteCurrencySymbol = response.data.data.siteCurrencySymbol

      if (queryString.reportGroupId) {
        this.reportGroupId = parseInt(queryString.reportGroupId as string)
      } else {
        this.reportGroupId = 1
      }

      if (queryString.reportId) {
        this.reportDefinition = this.reportItems.find((o) => {
          return o.id === parseInt(queryString.reportId as string)
        })
      } else {
        this.reportDefinition = this.reportItems[0]
      }

      if (this.isCompare === false) {
        this.reportDefinition.details = this.reportDefinition.standardDetails
      } else {
        this.reportDefinition.details = this.reportDefinition.compareDetails
      }

      this.reportId = this.reportDefinition.id
      this.title = this.reportDefinition.details.title

      this.loadData()

      this.backendLoadedData = true
    })
  }

  dataMaker(rows) {
    const clonedData = JSON.parse(JSON.stringify(rows))

    // If we do not have a chartType set then we do not populate chart
    if (this.reportDefinition.details.chartType === null || this.reportDefinition.details.chartType === undefined) {
      return
    }

    let data

    if (this.reportDefinition.details.chartGroupData !== true) {
      data = clonedData
    } else {
      let numberOfGroups = this.defaultChartNumberOfGroups
      if (
        this.reportDefinition.details.chartNumberOfGroups !== undefined &&
        this.reportDefinition.details.chartNumberOfGroups > 0
      ) {
        numberOfGroups = this.reportDefinition.details.chartNumberOfGroups
      }
      data = []
      clonedData.forEach((item, k) => {
        if (k < numberOfGroups) {
          data.push(item)
        } else {
          if (!data[numberOfGroups]) {
            data[numberOfGroups] = item
            data[numberOfGroups][this.reportDefinition.details.chartLabelField] = 'Other'
          } else {
            this.reportDefinition.details.chartHeaders.forEach((header, headerKey) => {
              if (header.isNumber && data[numberOfGroups][header.value]) {
                data[numberOfGroups][header.value] =
                  parseInt(data[numberOfGroups][header.value]) + parseInt(item[header.value])
              }
            })
          }
        }
      })
    }

    this.chart.chartData.labels = []
    this.chart.chartData.datasets = []

    let index = 0
    this.reportDefinition.details.chartHeaders.forEach((e, k) => {
      this.chart.chartData.datasets[index] = {
        data: [],
      }

      this.chart.chartData.datasets[index].label = e.text
      this.chart.chartData.datasets[index].backgroundColor = e.colorInChart
      this.chart.chartData.datasets[index].hidden = !e.showInChart

      data.forEach((item, itemKey) => {
        this.chart.chartData.labels[itemKey] = item[this.reportDefinition.details.chartLabelField]
        this.chart.chartData.datasets[index].data.push(parseInt(item[e.value]))
      })

      index++
    })

    this.$root.$emit('chart_data' + '_event', this.chart)
  }

  goToRoute() {
    const queryData = {
      reportId: this.reportId.toString(),
      reportGroupId: this.reportGroupId.toString(),
      dateFrom: this.reportDateFrom.toString(),
      dateTo: this.reportDateTo.toString(),
      reportSupplier: '',
      reportCustomerGroup: '',
      reportCustomerSubGroup: '',
      reportPriceList: '',
      reportWebshops: '',
      reportProductType: '',
      reportEnvironmentalFeeIncluded: this.reportEnvironmentalFeeIncluded ? '1' : '0',
      isCompare: this.isCompare ? '1' : '0',
    }

    if (this.isProductTypeVisible() && this.reportProductType !== null && this.reportProductType !== undefined) {
      queryData.reportProductType = this.reportProductType.toString()
    } else {
      delete queryData.reportProductType
    }

    if (this.isSupplierVisible() && this.reportSupplier !== null && this.reportSupplier !== undefined) {
      queryData.reportSupplier = this.reportSupplier.toString()
    } else {
      delete queryData.reportSupplier
    }

    if (
      this.isCustomerGroupVisible() &&
      this.reportCustomerGroup !== null &&
      this.reportCustomerGroup !== undefined
    ) {
      queryData.reportCustomerGroup = this.reportCustomerGroup.toString()
    } else {
      delete queryData.reportCustomerGroup
    }

    if (
      this.isCustomerGroupVisible() &&
      this.reportCustomerSubGroup !== null &&
      this.reportCustomerSubGroup !== undefined
    ) {
      queryData.reportCustomerSubGroup = this.reportCustomerSubGroup.toString()
    } else {
      delete queryData.reportCustomerSubGroup
    }

    if (this.isPriceListVisible() && this.reportPriceList !== null && this.reportPriceList !== undefined) {
      queryData.reportPriceList = this.reportPriceList.toString()
    } else {
      delete queryData.reportPriceList
    }

    if (this.isWebshopsVisible() && this.reportWebshops !== null && this.reportWebshops !== undefined) {
      queryData.reportWebshops = this.reportWebshops.toString()
    } else {
      delete queryData.reportWebshops
    }

    if (!this.isEnvironmentalFeeIncludedVisible()) {
      delete queryData.reportEnvironmentalFeeIncluded
    }

    if (this.reportDefinition.isDaily === true) {
      delete queryData.dateTo
    }

    if (this.reportGroupItems.length === 1) {
      delete queryData.reportGroupId
    }

    this.$router.push({
      name: 'Statistics/General/Sales',
      query: queryData,
    })

    this.loadData()
  }

  loadData() {
    if (this.loadingData === true || !this.validateReport()) {
      return
    }

    this.loadingData = true
    this.reportDefinition = this.reportItems.find((el) => {
      return el.id === this.reportId
    })

    if (this.isCompare === false) {
      this.reportDefinition.details = this.reportDefinition.standardDetails
    } else {
      this.reportDefinition.details = this.reportDefinition.compareDetails
    }

    if (this.reportDefinition.details.sortBy !== null && this.reportDefinition.details.sortBy !== undefined) {
      this.pagination.sortBy = this.reportDefinition.details.sortBy
    }
    if (this.reportDefinition.details.sortDesc !== null && this.reportDefinition.details.sortDesc !== undefined) {
      this.pagination.sortDesc = this.reportDefinition.details.sortDesc
    }

    this.title = this.reportDefinition.details.title

    this.reportPreviousDateFrom = moment(this.reportDateFrom, 'YYYY-MM').subtract(1, 'year').format('YYYY-MM')
    this.reportPreviousDateTo = moment(this.reportDateTo, 'YYYY-MM').subtract(1, 'year').format('YYYY-MM')

    this.isReportDetailsVisible = this.reportDefinition.hasDetailFields

    this.refreshHeaders()

    this.loader()
  }

  loader() {
    this.$axios
      .post('/v3/statistics/general/sales/get_sales_data', this.getPostData())
      .then((response) => {
        const data = response.data.data

        if (data.rows.length > 0) {
          // We use slice since we need to clone because else this.chartData = sortChartData will modify tableData as well
          this.hasData = true

          this.tableData = data.rows.slice()
          this.tableTotals = data.totals

          this.chartData = data.rows.slice()
          this.chartData = this.sortChartData(this.chartData)
          this.dataMaker(JSON.parse(JSON.stringify(this.chartData)))

          this.loadingData = false
        } else {
          this.hasData = false
          this.loadingData = false
        }
      })
      .catch((error) => {
        error.response.data.errors.forEach((v, i) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
        this.loadingData = false
      })
  }

  isSupplierVisible() {
    return this.reportGroupId === 1 && this.reportDefinition.isSupplierFilterVisible
  }

  isProductTypeVisible() {
    return this.reportGroupId === 1 && this.reportDefinition.isProductTypeFilterVisible
  }

  isCustomerGroupVisible() {
    return this.reportGroupId === 1 && this.reportDefinition.isCustomerGroupFilterVisible
  }

  isPriceListVisible() {
    return this.reportGroupId === 1 && this.reportDefinition.isPriceListFilterVisible
  }

  isWebshopsVisible() {
    return this.reportGroupId === 1 && this.reportDefinition.isWebshopFilterVisible
  }

  isEnvironmentalFeeIncludedVisible() {
    return (
      this.reportGroupId === 1 &&
      this.reportDefinition.isEnvironmentalFeeFilterVisible &&
      (this.reportProductType === null || this.reportProductType === 1)
    )
  }

  convertNumberToDisplayedString(number, currency = false) {
    let result = Math.round(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    if (currency) {
      result += ' ' + this.siteCurrencySymbol
    }
    return result
  }

  displayAsPercentage(number) {
    return (number * 100).toFixed(2) + '%'
  }

  /*
   * We sort data for chart, because tables sort the data correctly by itself
   */
  sortChartData(data: any[]) {
    const sortByMappedValue = (extractor) => (a, b) =>
      extractor(a) > extractor(b) ? 1 : extractor(b) > extractor(a) ? -1 : 0
    if (this.reportDefinition.details.chartSortByType === 'date') {
      data = data.sort(
        sortByMappedValue((o) =>
          moment(
            o[this.reportDefinition.details.chartSortBy],
            this.reportDefinition.details.chartSortByFormat,
          ).unix(),
        ),
      )
    } else {
      data = data.sort(sortByMappedValue((o) => -o[this.reportDefinition.details.chartSortBy]))
    }

    return data
  }

  validateReport() {
    let result = true

    const dateFromObject = moment(this.reportDateFrom, 'YYYY-MM')
    const dateToObject = moment(this.reportDateTo, 'YYYY-MM')

    if (this.isCompare) {
      if (this.reportDefinition.isMonthly && dateFromObject.year() !== dateToObject.year()) {
        vxm.alert.error({
          content: this.$t(
            'When using compare mode on monthly reports, date period must be within the same year',
          ) as string,
          title: this.$t('c:common:Error') as string,
        })
        result = false
      } else if (this.reportDefinition.isDaily && dateFromObject.format('YYYYMM') !== dateToObject.format('YYYYMM')) {
        // No need for this, since we do not show the dateTo at daily reports, and we do this check in back-end
      }
    }

    return result
  }

  exportToExcel() {
    this.exportToExcelLoading = true
    this.$axios
      .post('/v3/statistics/general/sales/get_sales_data/export_to_excel', this.getPostData())
      .then((response) => {
        const blob = b64toBlob(
          response.data.data.binary,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        )
        if (!window.navigator.msSaveOrOpenBlob) {
          // BLOB NAVIGATOR
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', this.getExcelFileName())
          document.body.appendChild(link)
          link.click()
        } else {
          // BLOB FOR EXPLORER 11
          window.navigator.msSaveOrOpenBlob(blob, this.getExcelFileName())
        }
      })
      .finally(() => {
        this.exportToExcelLoading = false
      })
  }

  refreshHeaders() {
    let result = []

    if (this.showReportDetails) {
      result = this.reportDefinition.details.tableHeaders
    } else {
      this.reportDefinition.details.tableHeaders.forEach((e) => {
        if (!e.isDetail) {
          result.push(e)
        }
      })
    }

    this.headers = result
  }

  getPostData() {
    const postData = {
      reportId: this.reportId,
      dateFrom: this.reportDateFrom,
      isCompare: this.isCompare,
      dateTo: null,
      productType: null,
      supplierIds: null,
      customerGroupIds: null,
      customerSubGroupIds: null,
      priceListIds: null,
      webshopIds: null,
      environmentalFeeIncluded: null,
      reportGroupId: null,
    }

    if (this.reportDefinition.isDaily !== true) {
      postData.dateTo = this.reportDateTo
    }

    if (this.isProductTypeVisible() && this.reportProductType !== null) {
      postData.productType = this.reportProductType
    }

    if (this.isSupplierVisible() && this.reportSupplier !== null && this.reportSupplier !== undefined) {
      postData.supplierIds = this.reportSupplier
    }

    if (
      this.isCustomerGroupVisible() &&
      this.reportCustomerGroup !== null &&
      this.reportCustomerGroup !== undefined
    ) {
      postData.customerGroupIds = this.reportCustomerGroup
    }

    if (
      this.isCustomerGroupVisible() &&
      this.reportCustomerSubGroup !== null &&
      this.reportCustomerSubGroup !== undefined
    ) {
      postData.customerSubGroupIds = this.reportCustomerSubGroup
    }

    if (this.isPriceListVisible() && this.reportPriceList !== null && this.reportPriceList !== undefined) {
      postData.priceListIds = this.reportPriceList
    }

    if (this.isWebshopsVisible() && this.reportWebshops !== null && this.reportWebshops !== undefined) {
      postData.webshopIds = this.reportWebshops
    }

    if (this.isEnvironmentalFeeIncludedVisible()) {
      postData.environmentalFeeIncluded = this.reportEnvironmentalFeeIncluded
    }

    if (this.reportGroupItems.length > 1) {
      postData.reportGroupId = this.reportGroupId
    }

    return postData
  }

  getExcelFileName() {
    let result = this.reportDefinition.details.title.replace(/\s+/g, '_') + '_' + this.reportDateFrom

    if (!this.reportDefinition.isDaily) {
      result += '_' + this.reportDateTo
    }

    return result.toLowerCase() + '.xlsx'
  }

  @Watch('reportMonthFrom')
  getPreviousPeriod(months) {
    this.reportDateFrom = moment()
      .subtract(parseInt(months) - 1, 'month')
      .format('YYYY-MM')
    this.reportDateTo = moment().format('YYYY-MM')

    this.goToRoute()
  }
}
